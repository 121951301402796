<template>
	<div class="mt-4">
		<FeedAnnouncements :announcements="announcements" />
		<FeedPosts :posts="posts" :page="page" :per-page="perPage" :pages="pages" />
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('newsfeed.posts'),
		}
	},
	components: {
		FeedAnnouncements: () => import('@/components/feed/FeedAnnouncements.vue'),
		FeedPosts: () => import('@/components/feed/FeedPosts.vue'),
	},
	data() {
		return {
			page: 1,
			perPage: 10,
			pages: 1,
		}
	},
	watch: {
		setupInfo() {
			this.updateList(this.page, this.perPage)
		},
	},
	computed: {
		...mapGetters({
			announcements: 'feed/announcements',
			posts: 'feed/posts',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.fetchAnnouncements()
		this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
		this.perPage = this.$route.query.perPage ? parseInt(this.$route.query.perPage) : 10
		this.updateList(this.page, this.perPage)
	},
	methods: {
		updateQuery(toPage, perPage) {
			if (this.$route.query.page != toPage || this.$route.query.perPage != perPage) {
				this.$router.replace({
					name: this.$route.name,
					query: {
						page: this.page,
						perPage: this.perPage,
					},
				})
			}
		},
		updateList(toPage, perPage) {
			if (this.setupInfo.accountType.student.value) {
				this.updateQuery(toPage, perPage)
				this.fetchPosts({ courseID: 0, page: toPage, perPage }).then(({ pages }) => {
					this.page = toPage
					this.perPage = perPage
					this.pages = pages
				})
			}
		},
		...mapActions('feed', ['fetchAnnouncements', 'fetchPosts']),
	},
}
</script>
